import {backendApi} from "../../backend-api-sr"

export const orderApiAyola = (() =>{
    const deleteOrderItem = ( async (reqBody) => {
        var url = '/api/ayola/order/delete-order-item'
        // var url = '/api/ayola/order'
        return await backendApi.fetchCore(url, reqBody)
    })
    const fetchCategory = ( async (subPath, reqBody) => {
        var url = '/api/ayola/order/category'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchCommodity = ( async (subPath, reqBody) => {
        var url = '/api/ayola/order/commodity'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchMenuList = ( async (subPath, reqBody) => {
        var url = '/api/ayola/order/resto-menu-list'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchOrderHeaderDetail = ( async (reqBody) => {
        var url = '/api/ayola/order/'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchPpn = ( async (subPath) => {
        var url = '/api/ayola/order/ppn'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchRestoImageMenu = ( async (subPath) => {
        var url = '/api/ayola/order/resto-image-menu'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchRestoTable = ( async (subPath) => {
        var url = '/api/ayola/order/resto-table'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchRestoMenu = ( async (subPath) => {
        var url = '/api/ayola/order/resto-menu'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchServiceCharge = ( async (subPath) => {
        var url = '/api/ayola/order/service-charge'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchTableStatus = ( async (reqBody) => {
        var url = '/api/ayola/order/get-table-stat'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const moveTable = ( async (reqBody) => {
        var url = '/api/ayola/order/move-table'
        return await backendApi.updateCore(url, reqBody, false, false, false)
    })
    const postOrder = ( async (reqBody) => {
        var url = '/api/ayola/order/resto-header-insert'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {deleteOrderItem, fetchCategory, fetchCommodity, fetchMenuList, fetchOrderHeaderDetail, fetchPpn, fetchRestoImageMenu, fetchRestoTable, fetchRestoMenu, fetchServiceCharge, fetchTableStatus, moveTable, postOrder};
})()